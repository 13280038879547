
import Footer from '../../components/Footer';
import Header from '../../components/Header';  
import Banner from './Banner';  
function Home() { 
	return( 
	<>
    <Header /> 
    <Banner/> 

    <main> 
    <section class="feature-area pt-110 pb-80">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-md-9">
                            <div class="section-title text-center mb-10">
                                <h2 class="title">Welcome to PSM College of Education </h2>
                                <p>At PSM College of Education, you can have it all Teacher Training education that broadens your mind, the chance to serve your community, the resources and the support and network you need for success.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="feature-item text-center mb-10">
                                <div class="feature-shape">
                                    <i class="fa-solid fa-volleyball"></i>
                                </div>
                                <div class="feature-content">
                                    <div class="feature-title">
                                        <h3>Sports Ground</h3>
                                    </div>
                                    <p>The sprawling sports ground enables all games like football, basket ball, handball, volley ball, throw ball, cricket.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="feature-item text-center mb-30">
                                <div class="feature-shape feature-shape-2">
                                    <i class="fa-solid fa-bus"></i>
                                </div>
                                <div class="feature-content">
                                    <div class="feature-title feature-title-2">
                                        <h3>Transport Facility</h3>
                                    </div>
                                    <p>Our transport facility provides safe conveyance for the children.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="feature-item text-center mb-30">
                                <div class="feature-shape feature-shape-4">
                                    <i class="fa-solid fa-chalkboard-user"></i>
                                </div>
                                <div class="feature-content">
                                    <div class="feature-title feature-title-4">
                                        <h3>Laboratories</h3>
                                    </div>
                                    <p>The well equipped and well constructed labs provide an enjoyable learning experience for the students.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="feature-item text-center mb-30">
                                <div class="feature-shape feature-shape-3">
                                    <i class="fa-solid fa-user-large"></i>
                                </div>
                                <div class="feature-content">
                                    <div class="feature-title feature-title-3">
                                        <h3>Parents Day</h3>
                                    </div>
                                    <p>Since have been visonary relable sofware engnern partne have been and visionary
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
            <section class="about-area section-bg-one section-notch" id="about"> 
                <div class="img-container">
                    <section class="home_placement aos-init aos-animate" data-aos="fade-up">
                      <div class="container">
                        <div class="row">
                          <div class="col-lg-5">
                            <div class="homplacement_content aos-init aos-animate" data-aos="fade-in">
                              <div class="pl-box1">
                                <div class="heading1">Course</div>
                                <p>The course of study shall be for a duration of one academic yearConsisting of 200 working days/curriculum transaction days (36 hours in a week-5 or 6 days in aweek ).</p>
                                <div class="view-class mt-10">
                                    <a class="thm-btn" href="/Course">See More</a>
                                </div>
                                <div class="placement_years">
                                  <h3>100%</h3>
                                  <p>Placement Percentage over the Years</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-7">
                            <div class="pl-box2 aos-init aos-animate" data-aos="fade-in">
                              <div class="placment_slider new">
                                <div class="hp-testi">
                                  <div class="row">
                                    <div class="col-lg-7">
                                      <div class="testimonial-data">
                                        <h3>Correspondent</h3>
                                        <h4>I am immensely pleased to know that PSM College of Education have become an l am mater for many of our young and future generations. Let me extend a very hearty welcome to all prospective parents who have shown an interest in PSM College of Education.</h4>
                                        <h4>At this juncture, I wish to recall Ln. Oxford S Ramanathan, who has the experience of running educational institutions for more then 40 years...</h4>
                                        <a href="/AboutUs" class="program_icon">
                                            Read More
                                          <img src="../assets/images/nav-arrow.webp" alt="arrow"/>
                                        </a>
                                      </div>
                                    </div>
                                    <div class="col-lg-5 img-postion">
                                      <img src="../assets/images/principal.jpg" class="img-fluid" alt="LN. OXFORD S RAMANATHAN"/>
                                      <p>Ln. Oxford S Ramanathan</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
            </section>

            <section class="class-area pt-110 pb-80" id="faciilities">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-9">
                            <div class="section-title text-center mb-30">
                                <h2 class="title">Our Facilities</h2>
                                <p>Here is what you can expect from a house cleaning from a Handy professional. Download the app to share further cleaning details and instructions!</p>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-xl-4 col-lg-4 col-md-6">
                            <div class="class-item mb-10">
                                <div class="class-img">
                                    <img src="../assets/images/library.jpeg" alt="class image" />
                                </div>
                                <div class="class-content">
                                    <h4 class="title"><a href="/Facilities">Library</a></h4>
                                    <p>Library is the heart of Educational Institutions. The main aim of library is to provide right documents to right users at right time...</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6">
                            <div class="class-item mb-10">
                                <div class="class-img">
                                    <img src="../assets/images/computer.jpg" alt="class image" />
                                </div>
                                <div class="class-content">
                                    <h4 class="title"><a href="/Computer">Computer Laboratory</a></h4>
                                    <p>he college enables the trainees to work in schools as teachers using technology in teaching and related work as well as for their own...</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6">
                            <div class="class-item mb-10">
                                <div class="class-img">
                                    <img src="../assets/images/psychology.jpg" alt="class image" />
                                </div>
                                <div class="class-content">
                                    <h4 class="title"><a href="/Psychology">Psychology Laboratory</a></h4>
                                    <p>Psychology Lab is a integral part of Faculty of Education. The institution has a equipped educational psychology laboratory, which helps...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="view-class text-center mt-10">
                                <a class="thm-btn" href="/Facilities">See More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
            <section class="counter-area section-bg-two section-notch pt-80 pb-60">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-6">
                            <div class="single-counter text-center pb-30">
                                <img src="../assets/images/icon/c-icon1.png" alt="" />
                                <h3><span class="odometer" data-count="2500">00</span><span class="plus">+</span></h3>
                                <p>Students Enrolled</p>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6">
                            <div class="single-counter text-center pb-30">
                                <img src="../assets/images/icon/c-icon2.png" alt="" />
                                <h3><span class="odometer" data-count="912">00</span><span class="plus">+</span></h3>
                                <p>Best Awards Won</p>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6">
                            <div class="single-counter text-center pb-30">
                                <img src="../assets/images/icon/c-icon3.png" alt="" />
                                <h3><span class="odometer" data-count="370">00</span><span class="plus">+</span></h3>
                                <p>Classes Completed</p>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6">
                            <div class="single-counter text-center pb-30">
                                <img src="../assets/images/icon/c-icon4.png" alt="" />
                                <h3><span class="odometer" data-count="648">00</span><span class="plus">+</span></h3>
                                <p>Our Total Courses</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
            <div class="portfolio-area pt-110 pb-80" id="gallery">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-9">
                            <div class="section-title text-center mb-50">
                                <h2 class="title">Our Gallery</h2>
                                <p>Here is what you can expect from a house cleaning from a Handy professional. Download the app
                                    to share further cleaning details and instructions!</p>
                            </div>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-12">
                            <ul class="portfolio-menu">
                                <li>see all</li>
                                <li data-filter=".cat1">Faciilities </li>
                                <li data-filter=".cat2">Transport </li>
                                <li data-filter=".cat3">Sports Ground</li>
                                <li data-filter=".cat4">Photoshgop</li>
                            </ul>
                        </div>
                    </div>
                    <div class="row grid text-center">
                        <div class="col-xl-3 col-lg-3 col-md-3 grid-item mb-30">
                            <div class="portfolio-item">
                                <div class="fortfolio-thumb">
                                    <img src="../assets/images/portfolio/1.jpg" alt="" />
                                </div>
                                <div class="portfolio-content">
                                    <div class="content-view">
                                        <a class="popup-image" href="../assets/images/portfolio/1-1.jpeg"><i
                                                class="icon fal fa-plus"></i></a>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 grid-item mb-30">
                            <div class="portfolio-item">
                                <div class="fortfolio-thumb">
                                    <img src="../assets/images/portfolio/3.jpg" alt="" />
                                </div>
                                <div class="portfolio-content">
                                    <div class="content-view">
                                        <a class="popup-image" href="../assets/images/portfolio/3-1.jpeg"><i
                                                class="icon fal fa-plus"></i></a>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 grid-item mb-30">
                            <div class="portfolio-item">
                                <div class="fortfolio-thumb">
                                    <img src="../assets/images/portfolio/4.jpg" alt="" />
                                </div>
                                <div class="portfolio-content">
                                    <div class="content-view">
                                        <a class="popup-image" href="../assets/images/portfolio/4-1.jpeg"><i
                                                class="icon fal fa-plus"></i></a>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 grid-item mb-30">
                            <div class="portfolio-item">
                                <div class="fortfolio-thumb">
                                    <img src="../assets/images/portfolio/5.jpg" alt="" />
                                </div>
                                <div class="portfolio-content">
                                    <div class="content-view">
                                        <a class="popup-image" href="../assets/images/portfolio/5-1.jpg"><i
                                                class="icon fal fa-plus"></i></a>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 grid-item mb-30">
                            <div class="portfolio-item">
                                <div class="fortfolio-thumb">
                                    <img src="../assets/images/portfolio/2.jpg" alt="" />
                                </div>
                                <div class="portfolio-content">
                                    <div class="content-view">
                                        <a class="popup-image" href="../assets/images/portfolio/2-1.jpeg"><i
                                                class="icon fal fa-plus"></i></a>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 grid-item mb-30">
                            <div class="portfolio-item">
                                <div class="fortfolio-thumb">
                                    <img src="../assets/images/portfolio/6.jpg" alt="" />
                                </div>
                                <div class="portfolio-content">
                                    <div class="content-view">
                                        <a class="popup-image" href="../assets/images/portfolio/6-1.jpeg"><i
                                                class="icon fal fa-plus"></i></a>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 grid-item mb-30">
                            <div class="portfolio-item">
                                <div class="fortfolio-thumb">
                                    <img src="../assets/images/portfolio/7.jpg" alt="" />
                                </div>
                                <div class="portfolio-content">
                                    <div class="content-view">
                                        <a class="popup-image" href="../assets/images/portfolio/7-1.jpeg"><i
                                                class="icon fal fa-plus"></i></a>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 grid-item mb-30">
                            <div class="portfolio-item">
                                <div class="fortfolio-thumb">
                                    <img src="../assets/images/portfolio/9.jpg" alt="" />
                                </div>
                                <div class="portfolio-content">
                                    <div class="content-view">
                                        <a class="popup-image" href="../assets/images/portfolio/9-1.jpg"><i
                                                class="icon fal fa-plus"></i></a>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <section class="blog-area section-bg-three section-notch pt-90 pb-90">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-md-9">
                            <div class="section-title section-title-white text-center mb-40">
                                <h2 class="title">Our Features</h2>
                                <p>To emerge as an Institute of Excellence in Teacher Education by evolving the Future Teachers with Learning, Teaching, and Research Skills through celebrating Tradition-cum-Heritage along with Modern Values</p>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-xl-3 col-lg-3 col-md-3">
                            <div class="blog-item mb-30">
                                <div class="blog-image"> 
                                    <img src="../assets/images/clg1.png" alt="image" /> 
                                </div>
                                <div class="blog-content"> 
                                    <h4 class="blog-title">Choice Based Credit System </h4>
                                    <p>A Student Teacher is expected to earn the credits assigned to successfully complete both Theory and Practicum components of the programme.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3">
                            <div class="blog-item mb-30">
                                <div class="blog-image"> 
                                    <img src="../assets/images/clg2.png" alt="image" /> 
                                </div>
                                <div class="blog-content"> 
                                    <h4 class="blog-title">Facilities</h4>
                                    <p>PSM College of Education provide the best possible facilities to enable our students to benefit from a modern learning environment.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3">
                            <div class="blog-item mb-30">
                                <div class="blog-image"> 
                                    <img src="../assets/images/clg3.png" alt="image" /> 
                                </div>
                                <div class="blog-content"> 
                                    <h4 class="blog-title">Attendance and Leave</h4>
                                    <p>Students are expected to attend class regularly. Leave will be granted only under very rare and exceptional circumstances.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3">
                            <div class="blog-item mb-30">
                                <div class="blog-image"> 
                                    <img src="../assets/images/clg4.png" alt="image" /> 
                                </div>
                                <div class="blog-content"> 
                                    <h4 class="blog-title">Reasearch and Development </h4>
                                    <p>The Research and development Center aim to create, a research culture for developing and promoting scientific temper and research. </p>
                                </div>
                            </div>
                        </div>

                        
                        
                        
                    </div>
                </div>
            </section> 

            <section class="blog-area w3l-price-2 pt-30 pb-30 rs-testimonial home5-testi-style">
                <div class="container"> 
                    <div class="pricing-style-w3ls row py-md-5">
                        <div class="pricing-chart col-lg-6">
                            <h3>News & Events </h3>
                            <div class="tatest-top"> 
                                <marquee direction="up" onmouseover="this.stop()" onmouseout="this.start()"> 
                                    <div class="single_event"> 
                                        <div class="marque-text">
                                            <div class="event-square text-center">
                                                <span class="line_break">
                                                    <span class="event_date">25 </span>
                                                    <span class="event_month">May </span>
                                                </span>
                                            </div> 
                                            <span class="news_content"> ANNUAL DAY - 2023</span> 
                                        </div>  
                                        <div>                                     
                                            <a class="circular_read_more" href="#">Read More</a>  
                                        </div> 
                                    </div>
                                    <div class="single_event"> 
                                        <div class="marque-text">
                                            <div class="event-square text-center">
                                                <span class="line_break">
                                                <span class="event_date">18 </span>
                                                <span class="event_month">May </span>
                                                </span>
                                            </div> 
                                            <span class="news_content"> Milad-un-Nabi Celebration-2023</span> 
                                        </div>
                                        <div>                                     
                                            <a class="circular_read_more" href="#">Read More</a>  
                                        </div> 
                                    </div>
                                    <div class="single_event"> 
                                        <div class="marque-text">
                                            <div class="event-square text-center">
                                                <span class="line_break">
                                                <span class="event_date">05 </span>
                                                <span class="event_month">May </span>
                                                </span>
                                            </div> 
                                            <span class="news_content"> BHARATHIDASAN BIRTHDAY CELEBRATION - 2023</span>
                                        </div> 
                                        <div>                                     
                                            <a class="circular_read_more" href="#">Read More</a>  
                                        </div> 
                                    </div>
                                    <div class="single_event">                                        
                                        <div class="marque-text">
                                            <div class="event-square text-center">
                                                <span class="line_break">
                                                    <span class="event_date">21 </span>
                                                    <span class="event_month">Jun </span>
                                                </span>
                                            </div> 
                                            <span class="news_content"> INTERNATIONAL YOGA DAY - 2023</span> 
                                        </div>
                                        <div>                                     
                                            <a class="circular_read_more" href="#">Read More</a>  
                                        </div>                                   
                                    </div> 
                                </marquee> 
                            </div> 
                        </div>

                        <div class="pricing-chart testi-box-style col-lg-6"> 
                            <h3>News & Events </h3>     
                            <div class="testi-item">                                        
                                <div class="item-content"> 
                                    <span class="icon-img">
                                        <img class="testi_img" src="../assets/images/quote2.png" alt=""/>
                                        <span class="circular_title"> B.Ed. Semester(II &IV)Exam Fees remittion</span>
                                            <div>
                                                <i class="far fa-calendar-alt" aria-hidden="true"></i> 
                                                <span class="circular_date">02-Jun-2023</span>
                                            </div>
                                        </span> 
                                        <a class="circular_read_more" href="#">Read More</a>  
                                </div>
                            </div>
                                            
                            <div class="testi-item">                                        
                                <div class="item-content"> 
                                    <span class="icon-img">
                                        <img class="testi_img" src="../assets/images/quote2.png" alt=""/>
                                        <span class="circular_title"> Last Working Day - B.Ed. I year (Sem -II )- Reg.</span>
                                        <div>
                                            <i class="far fa-calendar-alt" aria-hidden="true"></i> 
                                            
                                            <span class="circular_date">14-Jun-2023</span>
                                        </div>
                                    </span> 
                                    <a class="circular_read_more" href="#">Read More</a>  
                                </div>
                            </div> 
                        </div>                              
                    </div>
                </div>
            </section>

        </main>

    <Footer/>
    
        
  </> 
  ); 
  } 
  export default Home;