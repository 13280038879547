import Header from '../../components/Header';  
import Footer from '../../components/Footer'; 
function AboutUs() { 
	return( 
	<>  
    
   <Header />  

   <main> 
            <div class="page-title-area section-notch pt-50 pb-50">
                <div class="banner-overlay"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="page-title">
                                <h2>About Us</h2>
                                <div class="breadcrumb-list text-left">
                                    <ul>
                                        <li><a href="/">Home</a></li>
                                        <li>About Us</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
            <section class="about-area pt-50 pb-20">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-md-9">
                            <div class="section-title text-center mb-30">
                                <h2 class="title">Welcome to PSM College of Education </h2>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-md-9">
                            <ul class="tabs-nav nav hide_navsm">
                                <li class="nav-item"><a class="active" href="#about-psm">About PSM</a></li>
                                <li class="nav-item"><a class="" href="#vision-mission">Vision & Mission</a></li>
                                <li class="nav-item"><a class="" href="#PSM-foundation">PSM Foundation</a></li>
                                {/* <li class="nav-item"><a class="" data-bs-target="#our-leaders">Our Leaders</a></li>  */}
                            </ul>
                        </div>                    
                    </div>
                    
                </div>
            </section>
            <section id="tabs-content">
                <div class="counter-area pt-50 pb-100" id="about-psm">
                    <div class="container">
                        <div class="row justify-content-center align-items-center"> 
                            <div class="col-lg-6">
                                <div class="about-image">
                                    <img src="../assets/images/portfolio/about.jpg" alt="" />
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="about-content-two pl-50">
                                    <div class="section-title">
                                        <h2 class="title">About PSM</h2>
                                        <p>At PSM College of Education, you can have it all Teacher Training education that broadens your mind, the chance to serve your community, the resources and the support and network you need for success. </p>
                                        <p>You’ll learn to transform your talent into a career that fuels your passion.With both on-campus and online programs, we balance real-world connections and hands-on learning with the high-tech resources and accessibility you expect in a digital world.</p>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div class="counter-area pt-80 pb-80 bg-gray" id="vision-mission">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8 col-md-9">
                                <div class="section-title text-center mb-10">
                                    <h2 class="title">Vision & Mission </h2>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center"> 
                            <div class="container">
                                <div class="highlights-box">
                                   <div class="highlights-item">
                                      <div class="highlights-item-box">
                                         <p class="highlights-icon"><img alt="highlights" src="../assets/images/LM-highlight-icon1.png"/></p>
                                         <h6>Vision</h6>
                                         <p>To be the educational institution of preferred choice by ushering convergence of knowledge, skills and values through holistic education.</p>
                                      </div>
                                   </div>
                                   <div class="highlights-item">
                                      <div class="highlights-item-box">
                                         <p class="highlights-icon"><img alt="highlights" src="../assets/images/LM-highlight-icon2.png"/></p>
                                         <h6>Mission</h6>
                                         <p>To impart value-based quality education through effective teaching-learning processes. To continuously develop and improve holistic and innovative personality for global mobility.</p>
                                      </div>
                                   </div>
                                   <div class="highlights-item">
                                      <div class="highlights-item-box">
                                         <p class="highlights-icon"><img alt="highlights" src="../assets/images/LM-highlight-icon3.png" /></p>
                                         <h6>Goal</h6>
                                         <p>End-to-end mortgage lifecycle management</p>
                                      </div>
                                   </div>
                                </div>
                             </div>
                        </div>
                    </div>
                </div> 
                <div class="counter-area pt-80 pb-80" id="PSM-foundation">
                    <div class="container"> 
                        <div class="row justify-content-center"> 
                            <div class="col-lg-3 col-md-5 m-b30">
                                <div class="teachers-profile">
                                    <div class="img">
                                        <img src="../assets/images/principal.jpg"/>
                                    </div>
                                    <div class="teachers-info">
                                        <h4 class="name">Ln. Oxford S Ramanathan</h4>
                                        <span class="position">Correspondent</span>
                                    </div> 
                                </div>
                            </div>
                            <div class="col-lg-9 col-md-7">
                                <div class="teachers-details">
                                    <h2>PSM College of Education </h2>
                                    <p align="justify">I am immensely pleased to know that PSM College of Education have become an l am mater for many of our young and future generations. Let me extend a very hearty welcome to all prospective parents who have shown an interest in PSM College of Education.</p>
                                    <p align="justify">At this juncture, I wish to recall Ln. Oxford S Ramanathan, who has the experience of running educational institutions for more then 40 years, whose strong message is that the best minds of India should be in the field of Education. Our motto is to strive untiringly towards this objective.</p>
                                    <p align="justify">It is not merely sufficient that a teacher teaches well; in line with our traditions and culture through the eons, that saw the explosion of wisdom through our Education system.</p>
                                    <p align="justify">A teacher should have a central passion for his profession; shall be the central figure to guide, motivate, support, mentor, and care for the physical and mental wellbeing of a student.</p>
                                    <p align="justify">It is not just enough to produce good scholars or academics; a teacher should strive to produce a socially useful, aware and productive individual, who shall be at peace in his life pursuits; give and take joy to the environment and have compassion towards the fellow inhabitants of this world.</p>
                                    <p align="justify">In the pursuit of such excellence, this Institution shall leave no stone unturned and I extend an earnest invitation to all to join me in this noble venture.</p>
                                    <p align="justify">We place major emphasis on continuous improvement of our people not only in their academic parts but also in their leadership traits so that they are geared to deliver sustained values to their employers when they are placed after their successful completion of the courses.</p> 
                                    <div class="my-othercontribution">
                                        <h6>My other  contribution :</h6>                                    
                                        <ul>
                                            <li><b>1. President	</b><span>:</span><p>Tamilnadu Self  Financing College of Education  \ Management Association  ( TNSCEMA)</p></li> 
                                            <li><b>2. President	</b><span>:</span><p>Corporation Congress Committee, Salem (Ex)</p></li> 
                                            <li><b>3. President</b><span>:</span><p>The Salem Literary Society</p></li> 
                                            <li><b>4. President</b><span>:</span><p>All India Voters Forum</p></li> 
                                            <li><b>5. General</b><span>:</span><p>Salem Dist. INTUC</p></li> 
                                            <li><b>6. Secretary</b><span>:</span><p>Hon. Judicial I Class Magistrate,(Juvenile Ex.)</p></li> 
                                            <li><b>7. Joint Secretary</b><span>:</span><p>Yercaud Tourism Development Council</p></li> 
                                            <li><b>8. District Chairman</b><span>:</span><p>Lions Clubs International Dist-324-B2</p></li> 
                                            <li><b>9. Convener </b><span>:</span><p>Association of Tamilnadu Private TTI</p></li> 
                                            <li><b>10. Member</b><span>:</span><p>District Road Safety Council, Salem.</p></li> 
                                            <li><b>11. Member</b><span>:</span><p>Guild of Service, Salem.</p></li>  
                                        </ul>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </section> 
        </main>
   <Footer/>

  </> 
  ); 
  } 
  export default AboutUs;