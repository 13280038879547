import React from 'react';

function  Footer () {
  return (
  <>
  
  <footer>
            <div class="footer-area">
                <div class="footer-top">
                    <div class="container">
                        <div class="footer-top-wrapper">
                            <div class="row">
                                <div class="col-xl-4 col-lg-4 col-md-4">
                                    <div class="footer-top-single">
                                        <div class="footer-top-icon">
                                        <i class="fab fa-whatsapp"></i>
                                        </div>
                                        <div class="footer-top-content">
                                            <h5>Whatsapp</h5>
                                            <a href="https://wa.me/9842705888"><span>+91 9842705888</span></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4">
                                    <div class="footer-top-single">
                                        <div class="footer-top-icon">
                                        <i class="far fa-envelope"></i>
                                        </div>
                                        <div class="footer-top-content">
                                            <h5>Send us a Message</h5>
                                            <span>psmcollege7@gmail.com</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4">
                                    <div class="footer-top-single footer-top-single-2">
                                        <div class="footer-top-icon">
                                        <i class="fas fa-map-marker-alt"></i>
                                        </div>
                                        <div class="footer-top-content">
                                            <h5>Visit our Location</h5>
                                            <span>5/81-2, Rakkipatty, Salem-637504.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom pt-40 pb-20">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="footer-widget mb-40">
                                    <h3>About PSM College of Education</h3>
                                    <p>PSM College of Education, you can have it all Teacher Training education that broadens your mind, the chance to serve your community, the resources and the support and network you need for success. </p>
                                    <div class="footer-social">
                                        <h4>Follow us :</h4>
                                        <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                        <a href="#"><i class="fab fa-twitter"></i></a>
                                        <a href="#"><i class="fa-brands fa-linkedin-in"></i></a> 
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="footer-widget mb-40">
                                    <h3>News & Events</h3>
                                    <div class="footer-post">
                                        <div class="fp-single">
                                            <div class="thumb">
                                                <a href="blog-details.html"><img src="../assets/images/portfolio/1.jpg" alt="" /></a>
                                            </div>
                                            <div class="content">
                                                <h5><a href="blog-details.html">Why children need a Healthy Environment</a></h5>
                                                <span><i class="far fa-calendar-alt"></i>  24th March 2023</span>
                                            </div>
                                        </div>
                                        <div class="fp-single">
                                            <div class="thumb">
                                                <a href="blog-details.html"><img src="../assets/images/portfolio/4.jpg" alt="" /></a>
                                            </div>
                                            <div class="content">
                                                 <h5><a href="blog-details.html">Planting Seeds in the Hearts of Preschoolers</a></h5>
                                                 <span><i class="far fa-calendar-alt"></i> 24th March 2022</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="footer-widget mb-40">
                                    <h3>Newsletter Subscription</h3>
                                    <p>Enter your email and get latest updates and offers subscribe us</p>
                                    <form action="#!">
                                        <input type="text" placeholder="Enter your email"/>
                                        <button class="thm-btn">Subscribe Now!</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyright-area pt-30 pb-30">
                <div class="container">
                    <div class="row flex-row-reverse align-items-center">
                        <div class="col-xl-12 col-lg-12 col-md-12">
                            <div class="copyright-text">
                                <p>	&copy; 2022 PSM College Designed by <a href="#" target="_blank">PSM College of Education</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        <a href="#" id="scroll" ><span></span></a>
        </footer>

        

  </>
);
}

export default Footer;